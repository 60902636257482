import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import DealerSignUp from "./pages/delerlogin/DealerSignup";
import DealerLogin from "./pages/delerlogin/DealerLogin";
import CarValidation from "./pages/form/applyloan/carprice/CarValidation";
import LoanForm from "./pages/form/applyform/LoanForm";
import Preloader from "./components/preloader/Preloader";
import { useEffect, useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Admin from "./admin/pages/Admin";
import AdminRegister from "./admin/pages/adminform/AdminRegister";
import AdminLogin from "./admin/pages/adminform/AdminLogin";
import ValidationList from "./admin/components/validationList/ValidationList";
import DealerregisteredList from "./admin/components/registereddealerlist/DealerregisteredList";
import Carmodels from "./admin/pages/carmodels/Carmodels";
import LoanFollowUp from "./admin/pages/valuationcustomers/LoanFollowUp";
import LoanTodayFollowup from "./admin/pages/valuationcustomers/LoanTodayFollowup";
import LoanNotInterested from "./admin/pages/valuationcustomers/LoanNotInterested";
import LoanCompleted from "./admin/pages/valuationcustomers/LoanCompleted";
import LoanNotInterestedList from "./admin/components/loanApplications/LoanNotInterestedList";
import LoanCompletionList from "./admin/components/loanApplications/LoanCompletionList";
import Privacy from "./pages/Privacy";
import TodayFollowup from "./admin/components/loanApplications/TodayFollowup";
// Loan applications received
import LoanApplicationList from "./admin/components/loanApplications/LoanApplicationList";
import LoanFollowUpList from "./admin/components/loanApplications/LoanFollowUpList";
import  AdminCibilCheck  from "./admin/components/loanApplications/AdminCibilCheck";
// Apply Loan
import Responsive from "./pages/form/responsiveForm/Responsive";
import CibilProfile from "./pages/form/responsiveForm/CibilProfile";
import Cibilresult from "./pages/form/responsiveForm/Cibilresult";
import LoanNavbar from "./components/navbar/LoanNavbar";
//AdminRoutes

import Hero from "./pages/hero/Hero";
import { AuthProvider } from "./components/AuthContext/AuthorizationContext";
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const t = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(t);
    };
  }, []);

  return loading ? (
    <Preloader />
  ) : (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Navbar />
                  <Hero />
                </>
              }
            />
            <Route
              exact
              path="/dealersignup"
              element={
                <>
                  <DealerSignUp />
                </>
              }
            />
            <Route
              exact
              path="/dealerlogin"
              element={
                <>
                  <DealerLogin />
                </>
              }
            />

            <Route
              exact
              path="/privacy"
              element={
                <>
                   <LoanNavbar />
                  <Privacy />
                </>
              }
            />
            <Route
              exact
              path="/loanform"
              element={
                <>
                  <Navbar />
                  <LoanForm />
                </>
              }
            />
            <Route
              exact
              path="/usedcarvalidation"
              element={
                <>
                  <LoanNavbar />
                  <CarValidation />
                </>
              }
            />
            <Route
              exact
              path="/cibilresult"
              element={
                <>
                  <Navbar />
                  <Cibilresult />
                </>
              }
            />
            <Route
              exact
              path="/cibilprofile"
              element={
                <>
                  <CibilProfile />
                </>
              }
            />
            <Route
              exact
              path="/loanform/user/applyloan"
              element={
                <>
                  <LoanNavbar />
                  <Responsive />
                </>
              }
            />

            <Route path="/admin/dashboard" Component={Admin}></Route>

            <Route path="/adminsignup" Component={AdminRegister} />
            <Route path="/adminlogin" Component={AdminLogin} />
            <Route path="/admincarvalidationlist" Component={ValidationList} />
            <Route
              path="/admindealerregistered"
              Component={DealerregisteredList}
            />
            <Route
              path="/admindealerregistered"
              Component={DealerregisteredList}
            />
            {/* Loan applied  followup */}
            <Route path="/admin/loanfollowup" Component={LoanFollowUp} />
            <Route path="/admin/todayfollowup" Component={LoanTodayFollowup} />
            <Route
              path="/admin/loan/notinterested"
              Component={LoanNotInterested}
            />
            <Route path="/admin/loan/completed" Component={LoanCompleted} />
            <Route
              path="/admin/loan/applicationlist"
              Component={LoanApplicationList}
            ></Route>
             <Route
              path="/admin/loan/cibil"
              Component={AdminCibilCheck}
            ></Route>
            <Route
              path="/admin/loan/followuplist"
              Component={LoanFollowUpList}
            />
            <Route
              path="/admin/loan/notinterestedlist"
              Component={LoanNotInterestedList}
            ></Route>
            <Route
              path="/admin/loan/completionlist"
              Component={LoanCompletionList}
            />
            <Route path="/admincarvalidationmodel" Component={Carmodels} />
            <Route
              path="/admin/loan/today/followup"
              Component={TodayFollowup}
            />
            {/* <Route
              path="/admin/view/cibildetail"
              Component={CibilDetail}
            /> */}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}
<style></style>;
export default App;
