import React, { useState, useEffect } from "react";
import "./responsive.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { v4 as uuidv4 } from "uuid";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from "../../../components/modal/SucessModal";
import FailureModal from "../../../components/modal/FailureModal";
import { responseData } from "../../../admin/components/table/cibilDetail/responseData";
const UserInformation = ({ onCibilScoreChange, onNext, onUserIdChange }) => {
  const [inputValues, setInputValues] = useState({
    phone_number: "",
    otp: "",
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    pan_number: "",
    aadhar_number: "",
  });
  const [cibilScore, setCibilScore] = useState();
  const [orderId, setOrderId] = useState(uuidv4());
  const [cibilapiresponse, setCibilapiresponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dealerToken = localStorage.getItem("dealerToken");
  const dealerFirstName = localStorage.getItem("dealerFirstName");
  const dealerLastName = localStorage.getItem("dealerLastName");
  const dealerMobile = localStorage.getItem("dealerMobile");
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const numericPhoneNumber = parseInt(inputValues.phone_number);

  const generateOTP = async () => {
    try {
      const dataForOtp = {
        apiid: "AP100006",
        token: "7974416b-b6fc-49c0-b6ef-e4d96a82a602",
        methodName: "CreditReportEquifaxOTPGenerate",
        orderid: orderId,
        phone_number: `${numericPhoneNumber}`,
      };
      axios.post(`${BASE_URL}/websoftpay/otp`, dataForOtp).then((res) => {
        setModalMessage("OTP sent successfully, Please verify the OTP");
        setSuccessModal(true);
      });
    } catch (error) {
      setModalMessage("Error in sending otp try again");
      setShowFailureModal(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !inputValues.first_name ||
      !inputValues.last_name ||
      !inputValues.dob ||
      !inputValues.pan_number ||
      !inputValues.aadhar_number ||
      !inputValues.phone_number ||
      !inputValues.otp
    ) {
      setErrorMessage("Please fill in all the required fields.");
      return;
    }

    setErrorMessage("");

    const formattedDOB = () => {
      const dob = inputValues.dob;
      const parts = dob.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}-${month}-${year}`;
      }
      return "";
    };

    const requestBody = {
      apiid: "AP100006",
      token: "7974416b-b6fc-49c0-b6ef-e4d96a82a602",
      methodName: "creditreportEquifax",
      orderid: orderId,
      fname: inputValues.first_name,
      lname: inputValues.last_name,
      dob: formattedDOB(),
      phone_number: inputValues.phone_number,
      pan_num: inputValues.pan_number,
      otp: inputValues.otp,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/websoftpay/cibil`,
        requestBody
      );

      // console.log("api response"+JSON.stringify(response));
      // console.log("response.data: "+JSON.stringify(response.data));
      // console.log("response.data.data: "+JSON.stringify(response.data.data));
      // console.log("response.data.data.data: "+JSON.stringify(response.data.data.data));
      // console.log("response.data.data.data.Equifax_Report: "+JSON.stringify(response.data.data.data.Equifax_Report));
      // console.log("response.data.data.data.Equifax_Report.CCRResponse: "+JSON.stringify(response.data.data.data.Equifax_Report.CCRResponse));
      // console.log("response.data.data.data.Equifax_Report.CCRResponse.CIRReportDataLst: "+JSON.stringify(response.data.data.data.Equifax_Report.CCRResponse.CIRReportDataLst));
      // console.log("response.data.data.data.Equifax_Report.CCRResponse.CIRReportDataLst.[0].CIRReportData: "+JSON.stringify(response.data.data.data.Equifax_Report.CCRResponse.CIRReportDataLst[0].CIRReportData));

      setCibilapiresponse(response.data.data);

      const value =
        response.data.data.data.Equifax_Report.CCRResponse.CIRReportDataLst[0]
          .CIRReportData.ScoreDetails[0].Value;
      setCibilScore(value);
      // console.log("Value is userInformation: " + value);
      const updatedInputValues = {
        ...inputValues,
        cibilScore: value,
        dealerToken: dealerToken,
        dealerFirstName: dealerFirstName,
        dealerLastName: dealerLastName,
        dealerMobile: dealerMobile,
      };
      // console.log("updatedInputValues: " + JSON.stringify(updatedInputValues));
      const res = await axios.post(
        `${BASE_URL}/loanform/userInformation`,
        updatedInputValues
      );

      if (res.data.message === "Data inserted successfully") {
        onUserIdChange(res.data.user_id);
        // console.log("cibilreport from api", JSON.stringify(cibilapiresponse))
        const cibilRes = await axios.post(
          `${BASE_URL}/loanform/user/cibilreport`,
          {
            user_id: res.data.user_id,
            data: response.data.data,
          }
        );

        onNext();
      }
    } catch (error) {
      // console.error("Error making API call:", error);
      toast.error("Server Error Try again later");
    }

    // axios
    // .post("https://api.mybulksms.in/smsotp.php", {
    //   lg: "ssshdr",
    //   key0: "ssscdr7080",
    //   key1: "1207168983369592112",
    //   key2: "1001582263014226685",
    //   id: "SSSCDR",
    //   to: "8870800553",
    //   uc: "0",
    //   msg: `இனிய நல்வாழ்த்துகள் ${inputValues.first_name} ${inputValues.phone_number}Dr.சுரேஷ்பாபு98421333302 ஸ்ரீசத்யசுபாமருத்துவமனை7402740451பீரித்திமருத்துவமனை6384848497திண்டுக்கல்`,

    // })
    // .then((response) => {
    //  console.log(response,"Response from msg sending to the admin")
    // })
    // .catch((error) => {
    //   console.error("Failed to send SMS:", error);

    // });
  };

  onCibilScoreChange(cibilScore);

  return (
    <>
      <div className="container cibil-form  formbg">
        <div className="step1">
          {/* <h4>Check Your CibilScore</h4>
        <strong>Cibil Score :</strong> {cibilScore} */}
          {/* <p>{cibilScore}</p> */}
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between cibil-fields">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputFirstName1"
                  name="first_name"
                  placeholder="First Name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3 mx-3">
                <input
                  type="text"
                  name="last_name"
                  className="form-control"
                  id="exampleInputLastName1"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className=" mb-3 pt--5">
              <input
                type="mail"
                className="form-control"
                id="floatingInput"
                name="email"
                placeholder="Email"
                onChange={handleInputChange}
              />
            </div>

            <div className=" mb-3">
              <input
                type="date"
                className="form-control"
                id="floatingInput"
                name="dob"
                placeholder="dd-mm-yyyy"
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="exampleInputPhone"
                name="pan_number"
                placeholder="Pan Number"
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <input
                type="number"
                className="form-control"
                id="exampleInputPhone"
                name="aadhar_number"
                placeholder="Aadhar Number"
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3 d-flex">
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputPhone"
                  placeholder="Phone Number"
                  name="phone_number"
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  className="btn btn-primary px-4 py--4"
                  onClick={() => generateOTP()}
                >
                  Verify
                </button>
              </div>
            </div>
            <div
              className="d-flex justify-content-end text-warning"
              style={{ cursor: "pointer" }}
            >
              <p onClick={() => generateOTP()}>Resend OTP</p>
            </div>
            <div className="otp">
              <h6>OTP Verification</h6>
              <input
                type="number"
                id="otpform"
                name="otp"
                onChange={handleInputChange}
                placeholder="Enter your OTP"
              />
              <label htmlFor="otpform"></label>
            </div>

            {errorMessage && (
              <div className="error-message ">
                <p className="text-danger"> </p> {errorMessage}
              </div>
            )}

            <div className="d-flex my-2 mb-2 justify-content-center">
              <button
                className="btn btn-primary next-btn mx-2 mb-3 px-4 text-white"
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SuccessModal
        visible={showSuccessModal}
        message={modalMessage}
        onRequestClose={() => setSuccessModal(false)}
      />
      <FailureModal
        visible={showFailureModal}
        message={modalMessage}
        onRequestClose={() => setShowFailureModal(false)}
      />
    </>
  );
};

export default UserInformation;
